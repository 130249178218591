import { Routes } from '@angular/router';
import { RouteGuards } from '@ic/pwp-ui';
export const appRoutes: Routes = [
  {
   path: 'rbcloginv4wfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'RBCLoginV4.WF.App' },
    loadChildren: () => import('./jig/RBCLoginV4.WF.App/RBCLoginV4.WF.App.module').then(m => m.RBCLoginV4WFAppModule)
  },
  {
   path: 'bb8v4loginwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'BB8V4Login.WF.App' },
    loadChildren: () => import('./jig/BB8V4Login.WF.App/BB8V4Login.WF.App.module').then(m => m.BB8V4LoginWFAppModule)
  },
  {
   path: 'rbcv4wfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'RBCv4.WF.App' },
    loadChildren: () => import('./jig/RBCv4.WF.App/RBCv4.WF.App.module').then(m => m.RBCv4WFAppModule)
  },
  {
   pathMatch: 'full',
   path: 'RBCClientV4PHNUAT',
   loadChildren: () => import('./jig/RBCClientV4PHNUAT/RBCClientV4PHNUAT.module')
  },
  {
   pathMatch: 'full',
   path: 'RBCClientV4DSUAT',
   loadChildren: () => import('./jig/RBCClientV4DSUAT/RBCClientV4DSUAT.module')
  },
  {
   pathMatch: 'full',
   path: 'RBCClientV4DSMobile',
   loadChildren: () => import('./jig/RBCClientV4DSMobile/RBCClientV4DSMobile.module')
  },
  {
   pathMatch: 'full',
   path: 'RBCClientV4DSUATMobile',
   loadChildren: () => import('./jig/RBCClientV4DSUATMobile/RBCClientV4DSUATMobile.module')
  },
  {
   pathMatch: 'full',
   path: 'RBCClientV4PHN',
   loadChildren: () => import('./jig/RBCClientV4PHN/RBCClientV4PHN.module')
  },
  {
   pathMatch: 'full',
   path: 'RBCClientV4DS',
   loadChildren: () => import('./jig/RBCClientV4DS/RBCClientV4DS.module')
  },
  {
   pathMatch: 'full',
   path: 'RBCClientV4WIP',
   loadChildren: () => import('./jig/RBCClientV4WIP/RBCClientV4WIP.module')
  },
  {
   pathMatch: 'full',
   path: 'RBCClientV4ETUATMobile',
   loadChildren: () => import('./jig/RBCClientV4ETUATMobile/RBCClientV4ETUATMobile.module')
  },
  {
   pathMatch: 'full',
   path: 'RBCClientV4ETMobile',
   loadChildren: () => import('./jig/RBCClientV4ETMobile/RBCClientV4ETMobile.module')
  },
  {
   pathMatch: 'full',
   path: 'RBCClientV4ETUAT',
   loadChildren: () => import('./jig/RBCClientV4ETUAT/RBCClientV4ETUAT.module')
  },
  {
   pathMatch: 'full',
   path: 'RBCClientV4PHNUATMobile',
   loadChildren: () => import('./jig/RBCClientV4PHNUATMobile/RBCClientV4PHNUATMobile.module')
  },
  {
   pathMatch: 'full',
   path: 'RBCClientV4ET',
   loadChildren: () => import('./jig/RBCClientV4ET/RBCClientV4ET.module')
  },
  {
   pathMatch: 'full',
   path: 'RBCClientV4PHNMobile',
   loadChildren: () => import('./jig/RBCClientV4PHNMobile/RBCClientV4PHNMobile.module')
  },
];
